<template>
  <div id="content" class="app-content">
    <!-- page header -->
    <b-row>
      <b-col>
        <h1 class="page-header">Categories <small>all listings.</small></h1>
      </b-col>

      <b-col sm="auto" class="ml-auto">
        <b-button variant="default" :to="{ name: 'categories.new' }" :disabled="$cannot('category.create')">
          New Category
        </b-button>
      </b-col>
    </b-row>

    <!-- page content -->
    <b-card>
      <b-table :fields="fields" :items="listing" show-empty stacked="sm" bordered hover>
        <template v-slot:cell(options)="row">
          <b-button
            :to="{ name: 'categories.edit', params: { slug: row.item.slug } }"
            :disabled="$cannot('category.create')"
            variant="default"
            size="sm"
          >
            Edit
          </b-button>
        </template>
      </b-table>

      <!-- pagination -->
      <b-pagination
        v-model="meta.current_page"
        :total-rows="meta.total"
        :per-page="meta.per_page"
        @change="paginationChange"
      />
    </b-card>
  </div>
</template>

<script>
import { categories as categoriesApi } from '@/api'

export default {
  name: 'CategoryPage',
  inject: ['$cannot'],
  beforeRouteEnter(from, to, next) {
    next(vm => vm.loadContent())
  },
  data() {
    return {
      listing: [],
      meta: {
        current_page: 1,
        from: 0,
        last_page: 1,
        path: '',
        per_page: 0,
        to: 0,
        total: 0,
      },
    }
  },
  computed: {
    fields() {
      return [{ key: 'id', label: 'ID' }, { key: 'name' }, { key: 'options', label: '' }]
    },
  },
  methods: {
    async loadContent() {
      const page = this.$route.query.page ?? 1
      const response = await categoriesApi.go(page)

      this.$nextTick(() => {
        this.listing = response.data()
        this.meta = response.meta
      })
    },
    paginationChange(page) {
      this.$router.push(page === 1 ? { name: 'categories' } : { name: 'categories', query: { page } })

      this.loadContent()
    },
  },
}
</script>
