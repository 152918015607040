<template>
  <validation-observer v-slot="{ invalid }">
    <form @submit.prevent="submitFormData" enctype="multipart/form-data">
      <div id="content" class="app-content">
        <!-- BEGIN container -->
        <b-container>
          <!-- BEGIN row -->
          <b-row>
            <!-- BEGIN col-10 -->
            <b-col xl="12">
              <!-- BEGIN row -->
              <b-row>
                <!-- BEGIN col-9 -->
                <b-col xl="12">
                  <b-breadcrumb>
                    <b-breadcrumb-item :to="{ name: 'categories' }">
                      CATEGORIES
                    </b-breadcrumb-item>
                    <b-breadcrumb-item active>
                      {{ breadcrumbTitle }}
                    </b-breadcrumb-item>
                  </b-breadcrumb>

                  <b-row>
                    <b-col>
                      <b-card>
                        <validation-provider name="Name" v-slot="context" rules="required">
                          <b-form-group label="Name">
                            <b-input
                              v-model="name"
                              placeholder="e.g Hiking"
                              :state="getValidationState(context)"
                              v-bind="context.ariaInput"
                            />

                            <b-form-invalid-feedback v-bind="context.ariaMsg">
                              {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider name="Top Content" v-slot="context">
                          <b-form-group label="Top Content">
                            <small v-if="context.errors.length" v-bind="context.ariaMsg" class="text-danger form-text">
                              {{ context.errors[0] }}
                            </small>
                            <froala v-model="top_content" />
                          </b-form-group>
                        </validation-provider>

                        <validation-provider name="Dynamic SEO content" v-slot="context">
                          <b-form-group label="Dynamic SEO content">
                            <small v-if="context.errors.length" v-bind="context.ariaMsg" class="text-danger form-text">
                              {{ context.errors[0] }}
                            </small>
                            <froala v-model="bottom_content" />
                          </b-form-group>
                        </validation-provider>
                      </b-card>

                      <hr />
                      <b-card title="SEO">
                      <b-form-group label="Title">
                          <b-form-input v-model="seo.title" placeholder="title" />
                      </b-form-group>

                        <b-form-group label="Meta Title">
                          <b-form-input v-model="seo.meta_title" placeholder="e.g you need a big bowl" />
                        </b-form-group>

                        <b-form-group label="Meta Description">
                          <b-form-textarea v-model="seo.meta_description" placeholder="e.g ..." />
                        </b-form-group>

                        <b-form-group label="No Index">
                          <b-form-checkbox v-model="seo.no_index">
                            Enable No-Index
                          </b-form-checkbox>
                        </b-form-group>
                      </b-card>
                    </b-col>

                    <b-col cols="4">
                      <b-card>
                        <validation-provider name="Type" v-slot="context">
                          <b-form-group label="Type">
                            <b-form-select
                              v-model="type"
                              :options="typeList"
                              :state="getValidationState(context)"
                              v-bind="context.ariaInput"
                            />

                            <b-form-invalid-feedback v-bind="context.ariaMsg">
                              {{ context.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>

                        <hr />
                        <label>Parent Category</label>
                        <multi-select
                          v-model="parent"
                          placeholder="Pick a category"
                          track-by="name"
                          label="name"
                          :options="categoryOptions"
                          :searchable="true"
                          @open="findCategory"
                          @search-change="findCategory"
                        />
                      </b-card>

                      <template v-if="isEditPage">
                        <hr />
                        <b-card>
                          <b-row align-v="center">
                            <b-col>
                              <pre v-if="category.image.w300">{{ category.image }}</pre>

                              <div class="bg-secondary" style="width: 150px; height: 150px">
                                <img v-if="category.image.w300" :src="category.image.w300" class="w-100 h-100">
                              </div>

                              <b-form-file
                                v-model="image"
                                ref="image"
                                class="invisible"
                                accept="image/*"
                                @input="uploadImage"
                              />

                              <div class="mb-2">
                                <small class="text-red">
                                  <strong>The min allowed image width is 600px.</strong>
                                </small>
                              </div>

                              <div>
                                <b-button variant="default" @click="pickImage">
                                  Upload Image
                                </b-button>

                                <b-button v-if="category.image.w300" variant="danger" class="ml-2" @click="deleteImg">
                                  Delete Image
                                </b-button>
                              </div>
                            </b-col>
                          </b-row>
                        </b-card>
                      </template>

                      <hr />
                      <b-button variant="primary" type="submit" :disabled="invalid" block>
                        {{ buttonText }}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css'

import MultiSelect from 'vue-multiselect'
import { categories as categoriesApi } from '@/api'
import { getValidationState } from '../page-helpers'
import Froala from '@/components/Froala.vue'

export default {
  name: 'CategoriesFormPage',
  components: { Froala, MultiSelect },
  data() {
    return {
      category: {},
      name: '',
      top_content: '',
      bottom_content: '',
      type: null,
      categoryOptions: [],
      parent: null,
      image: null,
      seo: { meta_title: '', meta_description: '', no_index: false },
    }
  },
  computed: {
    isEditPage() {
      return this.$route.name === 'categories.edit'
    },
    breadcrumbTitle() {
      return this.isEditPage ? 'EDIT' : 'NEW'
    },
    buttonText() {
      return this.isEditPage ? 'Update Category' : 'Create Category'
    },
    typeList() {
      return [
        { value: null, text: 'Please select an option' },
        { value: 'category', text: 'Category' },
        { value: 'gifttips', text: 'Gift Tips' },
      ]
    },
  },
  beforeRouteEnter(from, to, next) {
    next(vm => vm.loadContent())
  },
  methods: {
    getValidationState,
    async loadContent() {
      if (!this.isEditPage) return

      const category = await categoriesApi.findBySlug(this.$route.params.slug)
      this.category = category.data
      this.name = this.category.name
      this.top_content = this.category.top_content
      this.bottom_content = this.category.bottom_content
      this.type = this.category.type
      if (this.category && this.category.seo) {
        this.seo = this.category.seo
      }
      this.parent = this.category.parent
    },
    submitFormData() {
      const content = {
        name: this.name,
        top_content: this.top_content,
        bottom_content: this.bottom_content,
        parent_id: this.parent ? this.parent.id : null,
        type: this.type,
        seo: this.seo,
      }

      console.log(content)
      if (this.isEditPage) {
        return this.handleUpdate(content)
      }
      console.log('creating')
      this.handleCreate(content)
    },
    async handleCreate(data) {
      console.log('här')
      const category = await categoriesApi.create(data)
      this.$bvToast.toast(`A new category with the name "${this.name}" was created successfully.`, {
        title: 'Category created.',
        variant: 'primary',
      })

      this.$router.push({ name: 'categories.edit', params: { slug: category.data.slug } })
    },
    async handleUpdate(data) {
      await categoriesApi.update(this.category.id, data)

      this.$bvToast.toast(`"${this.name}" was updated successfully.`, {
        title: 'Category updated.',
        variant: 'primary',
      })
    },
    async findCategory(name = '') {
      const response = await categoriesApi.all({ filter: { name } })

      this.categoryOptions = await response.data()
    },
    pickImage() {
      this.$refs.image.$el.querySelector('input').click()
    },
    async uploadImage(image) {
      if (image) {
        await categoriesApi.update(this.category.id, { image }).then(({data}) => {
          this.category.image = data.image

          this.$bvToast.toast(`New image was successfully added to category.`, {
            title: 'Image Uploaded.',
            variant: 'primary',
          })

          this.image = null;
        }).catch((error) => this.$bvToast.toast(error.message, {title: 'Something went wrong.', variant: 'danger'}))
      }
    },

    deleteImg() {
      categoriesApi.update(this.category.id, { deleteImage: true }).then(({data}) => {
        this.category.image = data.image

        this.$bvToast.toast(`Image was successfully deleted.`, {
          title: 'Image Deleted.',
          variant: 'primary',
        })

        this.image = null;
      }).catch((error) => this.$bvToast.toast(error.message, {title: 'Something went wrong.', variant: 'danger'}))
    }
  },
}
</script>
